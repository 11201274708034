import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDZ_VKfjnqfrAPf7J0HPBI6775XwpLP_Tg",
  authDomain: "starryai-fbd8c.firebaseapp.com",
  projectId: "starryai-fbd8c",
  storageBucket: "starryai-fbd8c.appspot.com",
  messagingSenderId: "246766913385",
  appId: "1:246766913385:web:2560146f34f81831385ff8",
  measurementId: "G-72923714M4",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

export const signInWithGoogle = () => {
  return signInWithPopup(auth, googleProvider)
    .then((result) => {
      console.log("[SIGNIN_SUCCESS]");
      const idToken = getIdToken();
      if (idToken) {
        idToken
          .then((idToken) => {
            // console.log(idToken);
            localStorage.setItem("idToken", idToken);
            // localStorage.setItem("name", "Ahmed Sheashaa");
            localStorage.setItem("image", "");
          })
          .catch((error) => {
            localStorage.removeItem("idToken");
            alert("[SIGNIN_ERROR]", error);
          });
      } else {
        localStorage.removeItem("idToken");
        console.log("no token");
      }
      return idToken;
    })
    .catch((error) => {
      alert("[SIGNIN_ERROR]", error);
    });
};

export const signInWithApple = () => {
  return signInWithPopup(auth, appleProvider)
    .then((result) => {
      console.log("[SIGNIN_SUCCESS]");
      const idToken = getIdToken();
      if (idToken) {
        idToken
          .then((idToken) => {
            // console.log(idToken);
            localStorage.setItem("idToken", idToken);
            // localStorage.setItem("name", "Ahmed Sheashaa");
            localStorage.setItem("image", "");
          })
          .catch((error) => {
            localStorage.removeItem("idToken");
            alert("[SIGNIN_ERROR]", error);
          });
      } else {
        localStorage.removeItem("idToken");
        console.log("no token");
      }
      return idToken;
    })
    .catch((error) => {
      alert("[SIGNIN_ERROR]", error);
    });
};

export const signOut = () => {
  localStorage.clear();
  return auth.signOut();
};

export const getIdToken = () => {
  try {
    return auth.currentUser.getIdToken();
  } catch (error) {
    return null;
  }
};
