import "./App.css";
import Login from "./components/Login/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import Header from "./components/Layout/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { auth, getIdToken } from "./Firebase";

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const idToken = await getIdToken();
        if (idToken) {
          localStorage.setItem("idToken", idToken);
        }
        if (location.pathname === "/") {
          navigate("/dashboard");
        }
      } else {
        navigate("/");
      }
    });
  }, [location, navigate]);

  if (location.pathname === "/" || !localStorage.getItem("idToken")) {
    return <Login />;
  }
  if (location.pathname === "/dashboard") {
    return (
      <div className="App">
        <Header />
        <Dashboard />
      </div>
    );
  }
  return <Login />;
}

export default App;
