import { useState, useEffect } from "react";
import { DASHBOARD_API } from "../../api";

export const ApiHealth = () => {
  const [health, setHealth] = useState({
    api: "live",
    creationSuccessRate: 100,
  });

  const refresh = () => {
    if (localStorage.getItem("idToken")) {
      DASHBOARD_API.get("/api/health")
        .then((response) => {
          setHealth(response.data);
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    refresh();
    const interval = setInterval(() => {
      refresh();
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  let severity = "live";
  if (
    health.api === "Red" ||
    health.api === "Grey" ||
    health.creationSuccessRate < 90
  ) {
    severity = "outage";
  } else if (health.creationSuccessRate < 95) {
    severity = "degraded";
  }

  return (
    <div className="flex items-center bg-lavender rounded-full py-2 px-8">
      <p className="text-sm font-bold text-darkMagenta inline-flex items-baseline">
        {(severity === "outage" && (
          <span className="rounded-full h-3 w-3 bg-danger"></span>
        )) ||
          (severity === "degraded" && (
            <span className="rounded-full h-3 w-3 bg-warning"></span>
          )) || <span className="rounded-full h-3 w-3 bg-success"></span>}
        <span className="ml-2 capitalize">{severity} </span>
      </p>
    </div>
  );
};
