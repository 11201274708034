import { useState, useRef, useEffect } from "react";
import { Button, Modal } from "flowbite-react";
import { DASHBOARD_API } from "../../api";

export const Pricing = ({ openModal, setOpenModal }) => {
  const [steps, setSteps] = useState(20);
  const [apiPrices, setApiPrices] = useState(null);
  const [selectedModel, setSelectedModel] = useState("realvisxl");
  const [highres, setHighres] = useState("lowres");
  const rootRef = useRef(null);

  const handleUpdateSteps = (e) => {
    if (e.target.value < 20) {
      setSteps(20);
    } else if (e.target.value > 150) {
      setSteps(150);
    } else {
      setSteps(Math.round(e.target.value / 10) * 10);
    }
    setSteps(e.target.value);
  };

  useEffect(() => {
    if (localStorage.getItem("idToken") && !apiPrices) {
      DASHBOARD_API.get("/api/pricing").then((response) => {
        setApiPrices(response.data);
      });
    }
  }, [apiPrices]);

  const aspectRatioOrder = [
    "square",
    "landscape",
    "smallportrait",
    "portrait",
    "wide",
  ];

  const aspectRatioDisplayNames = {
    square: "Square",
    landscape: "Landscape",
    smallportrait: "Small Portrait",
    portrait: "Portrait",
    wide: "Wide",
  };

  const modelCostMultiplier = {
    realvisxl: 2,
    stylevisionxl: 2,
    animaginexl: 2,
    anime_2: 2,
    anime_stylized: 2,
    anime_vintage: 2,
    pixelart: 2,
    anime_3: 2,
    hydra: 2,
    lyra: 1,
    fantasy: 1,
    cyberPunk: 1,
    portrait: 1,
    woolitize: 1,
    synthwave: 1,
    inpunk: 1,
    abstractWorld: 1,
    anime: 1,
    "3dArt": 1,
    argo: 1,
    argoV2: 1,
    cinematic: 1,
    photography: 1,
    scifi: 1,
    flatIllustration: 1,
    detailedIllustration: 1,
    "3dIllustration": 1,
  };

  const sdxlResolutions = {
    square: [768, 768],
    landscape: [853, 640],
    smallportrait: [640, 800],
    portrait: [640, 1138],
    wide: [1066, 640],
  };

  const sdxlModels = Object.keys(modelCostMultiplier).filter(
    (key) => modelCostMultiplier[key] === 2
  );

  if (sdxlModels.includes(selectedModel) && highres === "highres") {
    setHighres("lowres");
  }

  return (
    <div ref={rootRef} className="text-left">
      <Modal
        dismissible
        root={rootRef.current ?? undefined}
        show={openModal === "dismissible"}
        onClose={() => setOpenModal(undefined)}
        size={"4xl"}
      >
        <Modal.Header>API Pricing</Modal.Header>
        <Modal.Body>
          <p className="text-sm text-gray-500">
            The number of iterations/steps to run the model for. Higher
            iterations likely (but not necessarily) produce better results but
            take longer to run (and cost more)
          </p>
          <div className="flex items-center space-x-4">
            <div className="grow">
              <label className="text-sm text-gray-500" htmlFor="steps">
                Steps: &nbsp;
              </label>
              <input
                key="steps"
                type="number"
                id="steps"
                className="w-full border border-gray-300 rounded-md px-2 py-1 grow"
                min={20}
                max={150}
                step={10}
                value={steps}
                onChange={handleUpdateSteps}
              />
            </div>
            <div className="grow">
              <label className="text-sm text-gray-500" htmlFor="model">
                Model: &nbsp;
              </label>
              <select
                name="model"
                id="model"
                className="w-full border border-gray-300 rounded-md px-2 py-1 grow"
                value={selectedModel}
                onChange={(e) => setSelectedModel(e.target.value)}
              >
                {Object.entries(modelCostMultiplier).map(
                  ([model, multiplier]) => {
                    return (
                      <option value={model}>
                        {model}
                        {multiplier !== 1 && ` (x${multiplier})`}
                      </option>
                    );
                  }
                )}
              </select>
            </div>
            <div className="grow">
              <label className="text-sm text-gray-500" htmlFor="highres">
                High Resolution: &nbsp;
              </label>
              <select
                name="highres"
                id="highres"
                className="w-full border border-gray-300 rounded-md px-2 py-1 grow"
                value={highres}
                onChange={(e) => setHighres(e.target.value)}
              >
                <option value="lowres">False</option>
                {!sdxlModels.includes(selectedModel) && (
                  <option value="highres">True</option>
                )}
              </select>
            </div>
          </div>
          <br />
          <div className="space-y-6">
            <table className="table-fixed w-full text-left">
              <thead>
                <tr>
                  <th>Aspect Ratio</th>
                  <th>Dimensions</th>
                  <th>Steps</th>
                  <th>Price/Image</th>
                </tr>
              </thead>
              <tbody>
                {apiPrices &&
                  aspectRatioOrder.map((aspectRatio) => {
                    if (!apiPrices[aspectRatio]) {
                      return null;
                    }
                    return Object.keys(apiPrices[aspectRatio].resolution)
                      .filter((resolution) => {
                        return highres === resolution;
                      })
                      .map((resolution) => {
                        return (
                          <tr key={resolution}>
                            <td>{aspectRatioDisplayNames[aspectRatio]}</td>
                            <td>
                              {sdxlModels.includes(selectedModel)
                                ? sdxlResolutions[aspectRatio][0]
                                : apiPrices[aspectRatio].resolution[
                                    resolution
                                  ][0]}{" "}
                              x{" "}
                              {sdxlModels.includes(selectedModel)
                                ? sdxlResolutions[aspectRatio][1]
                                : apiPrices[aspectRatio].resolution[
                                    resolution
                                  ][1]}
                            </td>
                            <td>{steps}</td>
                            <td>
                              $
                              {resolution === "highres"
                                ? (
                                    apiPrices[aspectRatio].price.highres *
                                    steps *
                                    modelCostMultiplier[selectedModel]
                                  ).toFixed(4)
                                : (
                                    apiPrices[aspectRatio].price.lowres *
                                    steps *
                                    modelCostMultiplier[selectedModel]
                                  ).toFixed(4)}
                            </td>
                          </tr>
                        );
                      });
                  })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button color="gray" onClick={() => setOpenModal(undefined)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
