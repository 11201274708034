import { useState, useEffect } from "react";
import Page from "../Layout/Page";
import { Spinner } from "../Layout/Spinner";
import { RefreshIcon } from "../UI/RefreshIcon";
import { CopyIcon } from "../UI/CopyIcon";
import { ApiHealth } from "../Health/ApiHealth";
import { Pricing } from "../Pricing/Pricing";
import toast, { Toaster } from "react-hot-toast";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartJSTooltip,
  Legend,
} from "chart.js";
import * as d3 from "d3";
import { Line } from "react-chartjs-2";
import { DASHBOARD_API } from "../../api";
import { Tooltip } from "react-tooltip";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartJSTooltip,
  Legend
);

const Dashboard = () => {
  const COST_PER_IMAGE = 0.0048;
  const [creatingCheckoutSession, setCreatingCheckoutSession] = useState(false);
  const [addingCreditsAmount, setAddingCreditsAmount] = useState("");
  const [canAddCredits, setCanAddCredits] = useState(false);
  const [balance, setBalance] = useState(0);
  const [apiKey, setApiKey] = useState(null);
  const [apiKeyCreatedAt, setApiKeyCreatedAt] = useState(null);
  const [usageData, setUsageData] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);

  const [openModal, setOpenModal] = useState();

  const today = new Date();
  const thirtyDaysAgo = d3.timeDay.offset(today, -30);

  useEffect(() => {
    if (localStorage.getItem("idToken")) {
      const fetchData = () => {
        DASHBOARD_API.get("/api/key").then((response) => {
          setBalance(response.data.balance);
          setApiKey(response.data.api_key);
          setApiKeyCreatedAt(response.data.created_at);
          setUsageData(
            Object.entries(response.data.usage).map(([date, data]) => ({
              date: d3.timeFormat("%b %d")(d3.timeParse("%Y-%m-%d")(date)),
              creations: data.creations,
              spendings: data.spendings,
            }))
          );
          setLastUpdated(
            new Date().toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            })
          );
          if (response.data.is_new) {
            toast.custom((t) => (
              <div
                className={`${
                  t.visible ? "animate-enter" : "animate-leave"
                } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
              >
                <div className="flex-1 w-0 p-4">
                  <p className="text-sm text-gray-500">
                    ✅ You have successfully created your API key.
                  </p>
                </div>
              </div>
            ));
          }
        });
      };
      fetchData();
      const intervalId = setInterval(fetchData, 5 * 60 * 1000);
      return () => clearInterval(intervalId);
    }
  }, []);

  const dates = d3.timeDays(thirtyDaysAgo, today);
  const format = d3.timeFormat("%b %d");
  const labels = dates.map((date) => format(date));
  const creationsData = labels.map((label) => {
    const usage = usageData?.find((data) => data.date === label);
    return usage ? usage.creations : 0;
  });
  const spendingsData = labels.map((label) => {
    const usage = usageData?.find((data) => data.date === label);
    return usage ? usage.spendings : 0;
  });
  const data = {
    labels,
    datasets: [
      {
        label: "Creations",
        data: creationsData,
        borderColor: "#AA87E7",
        backgroundColor: "#AA87E780",
        yAxisID: "y",
      },
      {
        label: "Spendings ($)",
        data: spendingsData,
        borderColor: "#0D0D21",
        backgroundColor: "#0D0D2180",
        yAxisID: "y1",
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "API Usage (Last 30 Days)",
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    scales: {
      x: {
        display: true,
        time: {
          unit: "day",
        },
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        type: "linear",
        display: true,
        position: "left",
        grid: {
          drawOnChartArea: false,
        },
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    datasets: {
      1: {
        yAxisID: "y1",
      },
    },
  };

  const handleSubmitAddCredits = (event) => {
    event.preventDefault();
    setCreatingCheckoutSession(true);
    DASHBOARD_API.post("/api/balance", {
      amount: parseFloat(addingCreditsAmount),
    })
      .then((response) => {
        const url = response.data.checkout_URL;
        window.open(url, "_blank");
      })
      .finally(() => {
        setCreatingCheckoutSession(false);
      });
  };

  const handleManageBilling = () => {
    DASHBOARD_API.get("/api/invoices").then((response) => {
      const url = response.data.portal_URL;
      if (!url) {
        toast.custom((t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 w-0 p-4">
              <p className="text-sm text-gray-500">
                ❌ You haven't made any purchases yet
              </p>
            </div>
          </div>
        ));
        return;
      }
      window.open(url, "_blank");
    });
  };

  const handleCopyApiKey = (event) => {
    event.preventDefault();
    navigator.clipboard.writeText(apiKey);
    toast.custom((t) => (
      <div
        className={`${
          t.visible ? "animate-enter" : "animate-leave"
        } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
      >
        <div className="flex-1 w-0 p-4">
          <p className="text-sm text-gray-500">
            ✅ API Key copied to clipboard!
          </p>
        </div>
      </div>
    ));
  };

  const handleRefreshApiKey = () => {
    const confirm = window.confirm(
      "Are you sure you want to refresh your API Key? This will invalidate your current key."
    );
    if (confirm) {
      DASHBOARD_API.get("/api/key/refresh").then((response) => {
        setApiKey(response.data.api_key);
        setApiKeyCreatedAt(d3.timeFormat("%b %d, %Y")(new Date()));
        toast.custom((t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 w-0 p-4">
              <p className="text-sm text-gray-500">
                ✅ Your API Key has been updated.
              </p>
            </div>
          </div>
        ));
      });
    }
  };

  const handleChangeCredits = (event) => {
    event.preventDefault();
    setAddingCreditsAmount(event.target.value);
    setCanAddCredits(event.target.value >= 20 && event.target.value <= 10000);
  };

  return (
    <Page title="API Dashboard">
      <div className="mt-6 mx-8 sm:mx-10 md:mt-10 md:mx-14 lg:mt-12 lg:mx-16 xl:mt-14 xl:mx-24 2xl:mt-20 2xl:mx-48 text-darnkNavy">
        <div className="flex flex-wrap gap-4 items-center">
          <div className="grow">
            <h1 className="font-bold text-2xl text-left drop-shadow">
              API Dashboard
            </h1>
          </div>
          <ApiHealth />
        </div>
        <div className="grid grid-cols-12 py-8 text-left gap-4">
          <div className="col-span-12 lg:col-span-4">
            <div className="mb-5 p-5 bg-softLilac border border-deepPurple rounded-md">
              <h2 className="font-bold text-xl w-[400px]">Credits</h2>
              <br />
              <div className="font-semibold">
                {apiKey ? (
                  <>
                    ${balance.toLocaleString()}
                    <span
                      className="text-sm font-normal"
                      data-tooltip-id="base-pricing"
                      data-tooltip-content="Based on the cost of a 512*512 image. See pricing for more details."
                    >
                      &nbsp;~
                      {(balance / COST_PER_IMAGE).toLocaleString(undefined, {
                        maximumFractionDigits: 0,
                      })}{" "}
                      images*
                    </span>
                    <Tooltip
                      id="base-pricing"
                      className="text-sm font-normal"
                    />
                  </>
                ) : (
                  <Spinner />
                )}
              </div>
              <br />
              {apiKey && (
                <form onSubmit={handleSubmitAddCredits}>
                  <div className="relative">
                    <label htmlFor="addCredits" className="text-md font-medium">
                      Add Credits
                    </label>

                    <div className="relative">
                      <span className="absolute inset-y-6 left-4 flex items-center">
                        $
                      </span>
                      <input
                        type="number"
                        step="any"
                        min="20"
                        max="10000"
                        id="addCredits"
                        className="pl-8 block w-full mt-2 p-3 text-sm border border-deepPurple rounded-md focus:outline-none focus:ring-1 focus:ring-black focus:border-transparent"
                        placeholder="Add Credits"
                        autoComplete="off"
                        required
                        value={addingCreditsAmount}
                        onChange={handleChangeCredits}
                      />
                    </div>
                    <button
                      type="submit"
                      disabled={creatingCheckoutSession || !canAddCredits}
                      className={`text-white font-medium absolute right-0 bottom-0 bg-darkMagenta hover:deepPurple focus:ring-2 focus:outline-none focus:ring-black rounded-r-md text-sm px-4 py-3 border border-deepPurple hover:bg-black transition duration-300 ease-in-out ${
                        creatingCheckoutSession || !canAddCredits
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      }`}
                      style={{ userSelect: "none" }}
                    >
                      {creatingCheckoutSession ? <Spinner /> : "Add"}
                    </button>
                  </div>
                </form>
              )}
              {addingCreditsAmount > 0 && (
                <div>
                  <p className="mt-2 float-right text-sm">
                    +{(addingCreditsAmount / COST_PER_IMAGE).toFixed(0)} images
                  </p>
                  <br />
                </div>
              )}
            </div>
            <div className="mb-5 p-5 bg-softLilac border border-deepPurple rounded-md">
              <h2 className="font-bold text-xl w-[400px]">Info</h2>
              <br />
              <div className="font-semibold">
                <button
                  htmlFor="pricing_modal"
                  className="btn hover:text-black text-darkMagenta block pb-1"
                  onClick={() => setOpenModal("dismissible")}
                >
                  Pricing
                </button>

                <a
                  href="https://starryai.readme.io"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:text-black text-darkMagenta block pb-1"
                >
                  API Reference
                </a>
                <button
                  className="hover:text-black text-darkMagenta block pb-1"
                  onClick={handleManageBilling}
                >
                  Invoices
                </button>
                <a
                  href="mailto:support@starryai.com"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:text-black text-darkMagenta block pb-1"
                >
                  Support
                </a>
              </div>
            </div>
          </div>

          <div className="w-full grow place-self-start col-span-12 lg:col-span-8">
            <div className="mb-5 p-5 bg-softLilac border border-deepPurple rounded-md">
              <div className="flex flex-wrap gap-4 items-center">
                <div className="grow">
                  <h2 className="font-bold text-xl">API Key</h2>
                </div>
                <div title="Refresh Key" onClick={handleRefreshApiKey}>
                  <RefreshIcon />
                </div>
              </div>
              <br />
              <div className="flex flex-wrap py-3 text-left gap-4 border-solid border-0 border-b border-deepPurple">
                <div className="basis-2/3">
                  <p className="font-semibold text-sm">Key</p>
                </div>
                <div className="grow">
                  <p className="font-semibold text-sm">Date Created</p>
                </div>
              </div>
              <div className="flex flex-wrap py-3 text-left gap-4">
                <div className="basis-2/3" title="Click to copy">
                  <div className=" w-[75%] inline-flex">
                    <div
                      className="font-mono text-sm select-all"
                      onClick={handleCopyApiKey}
                    >
                      {apiKey ? (
                        apiKey.substring(0, 4) +
                        "*".repeat(apiKey.length - 8) +
                        apiKey.substring(apiKey.length - 4)
                      ) : (
                        <Spinner />
                      )}
                    </div>
                  </div>
                  <div
                    className="w-[25%] inline-flex"
                    onClick={handleCopyApiKey}
                  >
                    {apiKey && <CopyIcon />}
                  </div>
                  <Toaster
                    toastOptions={{
                      duration: 2000,
                      custom: {
                        duration: 2000,
                      },
                    }}
                    position="bottom-left"
                  />
                </div>
                <div className="grow text-sm font-mono">
                  {apiKey ? (
                    new Date(apiKeyCreatedAt).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                  ) : (
                    <Spinner />
                  )}
                </div>
              </div>
              <br />
              <p className="text-sm font-semibold">
                Documentation can be found at
                <a
                  href="https://starryai.readme.io"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:text-black text-darkMagenta"
                >
                  &nbsp;starryai.readme.io
                </a>
              </p>
            </div>
            <div className="mb-5 p-5 bg-softLilac border border-deepPurple rounded-md">
              <h2 className="font-bold text-xl">Usage History</h2>
              {lastUpdated && (
                <p className="text-sm">Last updated: {lastUpdated}</p>
              )}
              <Line options={options} data={data} />
            </div>
          </div>
        </div>
      </div>
      <Pricing openModal={openModal} setOpenModal={setOpenModal} />
    </Page>
  );
};

export default Dashboard;
