import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Menu } from "../UI/Menu";
import { Logo } from "../../assets/SVG/MainSVG";
import { ProfileThumb } from "../UI/ProfileThumb";
import { signOut } from "../../Firebase";
// import AppHealth from "../Health/AppHealth";

export const Header = () => {
  const navigate = useNavigate();
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const logoutHandler = () => {
    signOut();
    navigate("/");
  };

  return (
    <>
      <div className="flex justify-between items-center flex-wrap whitespace-nowrap py-4 2xl:py-5 pr-8 pl-6 border-b border-lightGray">
        <Link to="/dashboard">
          <Logo fill="#000000" width={130} height={44} />
        </Link>
        <div className="flex items-center ">
          {/* <AppHealth /> */}
          <div className="md:flex items-center hidden"></div>
          <div className="relative cursor-pointer">
            <div onClick={() => setIsProfileOpen(!isProfileOpen)}>
              <ProfileThumb profileThumb={localStorage.getItem("image")} />
            </div>
            <Menu
              isOpen={isProfileOpen}
              close={() => setIsProfileOpen(false)}
              position="right"
              style={{ width: "12rem" }}
            >
              <li
                className="py-2 px-6 hover:bg-gray-200 text-left"
                tabIndex={0}
                onClick={logoutHandler}
              >
                <span className="text-sm">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    class="feather feather-log-out inline"
                  >
                    <g>
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                      <polyline points="16 17 21 12 16 7"></polyline>
                      <line x1="21" y1="12" x2="9" y2="12"></line>
                    </g>
                  </svg>
                  &nbsp; Logout
                </span>
              </li>
            </Menu>
          </div>
        </div>
      </div>
      <div className="w-full py-3 bg-white z-[9999] fixed bottom-0 md:hidden flex items-center justify-evenly border-t  transform  transition-transform"></div>
    </>
  );
};

export default Header;
