import axios from "axios";
import { getIdToken } from "./Firebase";

export const DASHBOARD_API = axios.create({
  //`https://staging.starryai.com`
  //`http://127.0.0.1:4999`
  baseURL: `https://app.starryai.com`,
  withCredentials: true,
});

// export const PUBLIC_API = axios.create({
//   baseURL: `http://127.0.0.1:4999`,
//   withCredentials: true,
// });

const refreshToken = () => {
  const firebaseToken = getIdToken();
  if (firebaseToken) {
    firebaseToken
      .then((idToken) => {
        // console.log(idToken);
        if (idToken) {
          console.log("token is valid");
          localStorage.setItem("idToken", idToken);
        } else {
          console.log("no token");
        }
      })
      .catch((error) => {
        localStorage.removeItem("idToken");
        console.warn("[REFRESH_TOKEN_ERROR]", error);
        alert("[REFRESH_TOKEN_ERROR]", error);
      });
  } else {
    localStorage.removeItem("idToken");
    console.log("no token");
  }
};

DASHBOARD_API.interceptors.request.use(
  (config) => {
    refreshToken();
    const idToken = localStorage.getItem("idToken");
    if (idToken) {
      config.headers.Authorization = idToken;
      return config;
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          const idToken = localStorage.getItem("idToken");
          if (idToken) {
            config.headers.Authorization = idToken;
            resolve(config);
          } else {
            resolve(config);
          }
        }, 3000);
      });
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

DASHBOARD_API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 403 && !localStorage.getItem("idToken")) {
      console.warn("[AUTH_ERROR]", error);
      localStorage.clear();
      window.location.href = "/";
      return DASHBOARD_API(error.config);
    }
    return Promise.reject(error);
  }
);
